import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/global.less'
import { useScreenSize } from '@/use/useScreenSize.js'
import 'element-plus/theme-chalk/display.css'
import '@/assets/font/font.less';

const { screenWidth, screenSize } = useScreenSize()

const app = createApp(App)
app.provide('$screenWidth', screenWidth)
app.provide('$screenSize', screenSize)

// 按需引入组件
// import { ElButton } from 'element-plus';   
// 全局注册组件
// app.component('ElButton', ElButton)

app.use(store)
    .use(router)
    .mount('#app')
