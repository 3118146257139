import { createRouter, createWebHistory } from 'vue-router'
import routes from './route'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    let flag = true
    let rootList = ['数组里面写哪些路由切换不需要跳转到顶部']
    rootList.forEach(root => {
      if(to.fullPath.includes(root) && from.fullPath.includes(root)){
        flag = false
      }
    })
    if(from.fullPath.includes('productCenter') && to.fullPath.includes('productCenter')){
        flag = false
    }
    if(flag){
      return { left: 0, top: 0 }
    }
  },
  routes
})

export default router
