// 按照惯例，组合式函数名以“use”开头
export function useScreenSize() {
  let screenWidth = ref(null);
  let screenSize = ref(null);
  screenWidth.value = document.body.clientWidth;
  window.onresize = () => {
    return (() => {
      screenWidth.value = document.body.clientWidth;
      console.log(screenWidth);
    })();
  };
  watch(screenWidth, async (newVal, oldVal) => {
    console.log('watch', newVal);
    if (newVal >= 992) {
      screenSize.value = 'md';
    } else {
      screenSize.value = 'sm';
    }
  }, {
    immediate: true
  });

  // 通过返回值暴露所管理的状态
  return {
    screenWidth,
    screenSize
  };
}